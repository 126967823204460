import React from 'react';
import { navigate } from 'gatsby';

import MintLayout from '@/sections/mint/MintLayout';
import MintCard from '@/sections/mint/MintCard';

const MintError = () => {
  const handleBack = () => {
    navigate(-1);
  };

  return (
    <MintLayout>
      <div className="min-h-screen">
        <MintCard title={"Something's gone wrong."}>
          <div className="font-medium text-center">
            <p>Something's gone wrong again</p>
            <p>And again, and again, and again, again</p>
          </div>
        </MintCard>

        <div className="mt-2">
          <button type="button" onClick={handleBack} className='flex items-center justify-center w-full rounded-2xl py-5 px-10 font-bold transition bg-[#C2E900] hover:bg-[#C2E900]/80'>Try again</button>
        </div>
      </div>
    </MintLayout >
  );
};

export default MintError;